@import '../../styles/vars';
@import '../../styles/mixins/type';

.sidebar-nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    margin: 0;
    font-size: 18px;
  }

  &__link {
    color: $color-black50;
    display: flex;
    padding: 8px 40px;
    transition: color 180ms ease;
    align-items: center;

    &:hover {
      color: $color-black;
      // text-decoration: underline;
    }
    &.active {
      color: $color-black;

      span {
        border-bottom: 2px solid #1e40af;
      }
    }

    .lang-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .lang-button-en {
      margin-left: 0.2em;
    }

    .lang-button-ar {
      margin-right: 0.2em;
    }

    %icon {
      position: relative;
      top: -2px;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }

    .icon-en {
      @extend %icon;
      margin-right: 16px;
    }

    .icon-ar {
      @extend %icon;
      margin-left: 16px;
    }
  }
}
