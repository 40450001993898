@import "../../styles/vars";
@import "../../styles/mixins/type";
@import "../../styles/funcs";

.checkbox {
  position: relative;
  display: inline-block;

  &__box {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    // top: -0.09em;
    outline: none;
    cursor: pointer;
    user-select: none;
  }
  &__box input {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__box-inner {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    background: $color-black90;
    border-radius: $border-radius-small;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: transparent no-repeat 50% 50%;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiIHdpZHRoPSIyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNS42IDEwLjkxLTUuMDYtNC44NiAxLjQ2LTEuNDYgMy40NSAzLjQ0IDYuNDUtNy40NCAxLjUxIDEuMzF6IiBmaWxsPSIjZmZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzIDUpIi8+PC9zdmc+");
    }
  }
  &__box:hover &__box-inner {
    background: $color-black90;
  }
  &__box input:active ~ &__box-inner {
    background-color: shade($color-black90, 10%);
  }
  &__box input:checked ~ &__box-inner {
   background-color: $color-button-primary-hover;
   animation: tap 300ms linear;
  }
  &__box input:checked:active ~ &__box-inner {
    background-color: shade($color-button-primary-hover, 10%);
  }

  &__label {
    font-size: 13px;
    color: $color-black40;
    display: inline-block;
    padding: 0 0 0 34px;
  }

  &_with-label &__box {
    position: absolute;
  }
}
// .checkbox input {
//   position: relative;
//   z-index: -1;
//   display: inline-block;
//   vertical-align: middle;
//   width: 20px;
//   height: 20px;
//   margin-left: -35px;
// }
// .checkbox__box {
//   position: relative;
//   display: inline-block;
//   vertical-align: middle;
//   margin-right: 15px;
//   margin-left: -20px;
//   width: 20px;
//   height: 20px;
//   background: $color-black90;
//   border-radius: $border-radius-small;
//   user-select: none;

//   &:hover {
//     background: $color-black90;
//   }
//   &:after {
//     content: '';
//     display: block;
//     width: 100%;
//     height: 100%;
//     background: transparent no-repeat 50% 50%;
//     background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiIHdpZHRoPSIyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNS42IDEwLjkxLTUuMDYtNC44NiAxLjQ2LTEuNDYgMy40NSAzLjQ0IDYuNDUtNy40NCAxLjUxIDEuMzF6IiBmaWxsPSIjZmZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzIDUpIi8+PC9zdmc+");
//   }
// }
// .checkbox input:active ~ .checkbox__box {
//   background-color: shade($color-black90, 10%);
// }
// .checkbox input:checked ~ .checkbox__box {
//  background-color: $color-button-primary-hover;
//  animation: tap 300ms linear;
// }
// .checkbox input:checked:active ~ .checkbox__box {
//   background-color: shade($color-button-primary-hover, 10%);
// }

@keyframes tap {
  0% {
    box-shadow: 0 0 0 0 rgba($color-button-primary-hover, 30%);
  }
  100% {
    box-shadow: 0 0 2px 2px rgba($color-button-primary-hover, 0%);
  }
}
