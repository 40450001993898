@import "../../styles/vars";
@import "../../styles/mixins/type";

.otp-input {
  font-size: 68px;
  letter-spacing: .5rem;
  position:relative;
  left: .25em;
  width: 5em;
  margin: 16px auto;

  &:before {
    content: '000000';
    position: absolute;
    color: $color-black90;
  }

  &_value:before {
    display:none;
  }

  &__input {
    letter-spacing: .25em;
    position: relative;
    width: 100%;
    min-width: 3.5rem;
    border: 0;
    background: transparent;

    &:focus,
    &:active {
      outline: none;
    }
  }
}
