@import "./vars";
@import "./mixins/type";
@import "./normalize";
@import "./custom";
@import "./animation";
@import url('https://fonts.googleapis.com/css2?family=Univers+Next+Pro&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  font: normal 1em/#{1.25} $font-primary;
}

body {
  font: normal 1em/#{1.25} $font-primary;
  background: $color-background;
}

#root {
  display: flex;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

// Basic typography
::placeholder {
  color: $color-black60;
}

a,
.link {
  color: $color-button-primary-hover;
  text-decoration: none;
  cursor: pointer;
  padding: 7px 12px;

  &:hover {
    color: #411351;
    background-color: #6542BE08;
    text-decoration: none;
  }
}

p {
  margin: 0 0 1rem;
}

.nobr {
  white-space: nowrap;
}

.text_align_left {
  text-align: left;
}

.text_align_right {
  text-align: right;
}

.text_align_center {
  text-align: center;
}

.color_black40 {
  color: $color-black40;
}

.color_black60 {
  color: $color-black60;
}


.color-black {
  color: $color-black;
}

.color-black40 {
  color: $color-black40;
}

.color-black60 {
  color: $color-black60;
}

.color-link {
  color: $color-button-primary-hover;
}

.color-highlight {
  color: $color-yellow;
}

.color-danger {
  color: $color-red;
}

.min-height-100 {
  height: 100%;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.iti.iti--allow-dropdown.iti--show-flags.iti--inline-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0;
  border: 0;
  padding-left: 0;
  height: 45px;
}

input.iti__tel-input {
  border: none;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  padding: 0 !important;
  cursor: text;
  direction: ltr;
  flex-grow: 2;
  margin: 0 0 0 0.3rem !important;
}

.iti__flag-container {
  width: 5.6rem;
  height: 2.5rem;
  position: relative;
  box-sizing: border-box;
}

input.iti__tel-input {
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &:focus-visible {
    outline: none;
  }
}

span.input-phone__label-border-en {
  text-align: justify;
}

.iti--show-selected-dial-code .iti__selected-flag {
  background-color: transparent;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti {
  display: flex !important;
  background-color: #f0f2f2;
  height: 4rem !important;
  border-radius: 0.5rem !important;
}

.iti__tel-input {
  background-color: transparent;
}

.underline-link-text {
  color: #1e40af;
  cursor: pointer;
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;
}

.underline-link-text:hover {
  text-decoration: underline;
}
