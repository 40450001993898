@import "../../styles/vars";
@import "../../styles/mixins/type";

.input-phone {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  padding: 0;
  margin: 0;
  cursor: text;
  // phone input feild will always use english mode even when the language is in arabic
  // this is because numbers are still expected to be inputted in the english standard
  direction: ltr;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-black95;
    border: 2px solid $color-black95;
    border-radius: $border-radius;
    will-change: border-color, background-color;
    transition: border-color 100ms, background-color 100ms;
  }

  &:hover {
    &:before {
      border-color: $color-black90;
      background-color: $color-black90;
    }
  }

  &__select {
    position: relative;
    padding: 1.3rem 0 1.2rem;
    white-space: nowrap;

    &-wrap {
      padding: 0 1rem;
      border-right: 1px solid $color-black80;
    }
    .flag {
      margin-right: 1rem;
    }
  }
  &__wrap {
    position: relative;
    flex-grow: 1;
  }

  &__input {
    font-size: 16px;
    position: relative;
    display: block;
    width: 100%;
    margin: -2px 0 0;
    padding: 1.3rem 1rem 1.2rem;
    background: transparent;
    border: 0;
    border-radius: $border-radius;
    opacity: 0;
    transition: opacity 300ms;
    will-change: opacity;

    &:focus {
      outline: none;
    }
  }

  %__label {
    color: $color-black64;
    font-size: 16px;
    position: absolute;
    top: 50%;
    user-select: none;
    transform: translate3d(0, -50%, 0) scale(1);
    transition: opacity 300ms;
    will-change: transform;
  }

  &__label-en {
    @extend %__label;
    left: 1rem;
  }

  &__label-ar {
    @extend %__label;
    right: 1rem;
  }

  &_focused {
    &,
    &:hover {
      &:before {
        border-color: $color-button-primary-hover;
        background-color: $color-black98;
      }
    }
  }
  &_focused,
  &_filled {
    .input-phone__label-en {
      opacity: 0;
      // transform: translate3d(-12.5%, -1.566rem, 0) scale(0.75);
      // transform: translate3d(0, -1.566rem, -12.5%) scale(0.75);
    }

    .input-phone__label-ar {
      opacity: 0;
      // transform: translate3d(-12.5%, -1.566rem, 0) scale(0.75);
      // transform: translate3d(0, -1.566rem, -12.5%) scale(0.75);
    }
    .input-phone__input {
      opacity: 1;
    }
  }

  // Input with error
  &_error {
    &,
    &:hover {
    //&.input__focused,
    //&.input__focused:hover {
      &:before {
        border-color: $color-red;
      }
    }
  }

  // Disabled
  &_disabled {
    &,
    &:hover {
      &:before {
        background: $color-black90;
        border-color: $color-black90;
      }
    }
    .input__input {
      color: $color-black40;
    }
    .input__placeholder {
      color: $color-black64;
    }
  }

  // No label
  &_no-label {
    .input-phone__input {
      padding: 1.25rem 1rem 1.25rem;
    }
  }
}
