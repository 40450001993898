@import "../../styles/vars";
@import '../../styles/mixins/breakpoints';

.mobile-header {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  height: $mobile-header__height;
  background: #fff;
  box-shadow: $elevate-1;

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    padding: 4px 0;
  }
  &__logo {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  &__logo-inner {
    display: block;
    width: 120px;
    margin-top: 0px;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &__button {
    font-size: 22px;
    color: $color-black60;
  }

  @include media-breakpoint-down(lg) {
    display: block;
  }
}
