.country_code_container {
  width: 129px;
  height: 40px;

  .country_code__control {
    width: 100%;
    height: 55px;
    background-color: #f1f2f2;
    box-shadow: none;
    border: none !important;

    // &:hover {
    //   background-color: #e3e6e6;
    // }
    span.flag {
      margin-right: 12px;
    }

    .country_code__indicator-separator {
      margin-bottom: 18px;
      margin-top: 18px;
    }

    // .country_code__value-container {
    //   justify-content: flex-end;
    // }
  }

  .country_code__menu {
    width: max-content !important;

    .country_code__option {
      padding: 15px 10px;

      span.flag {
        margin-right: 12px;
      }
    }
  }
  .country_code__single-value .country_name{
    display: none;
  }
}

@media (min-width: 300px) and (max-width: 360px) {
  .country_code_container {
    width: 95px;
    height: 40px;

    .country_code__control {
      span.flag {
        margin-right: 3px;
      }
    }

    .country_code__menu {
      width: fit-content !important;

      .country_code__option {
        span.flag {
          margin-right: 3px;
        }
      }
    }

    .country_code__indicator {
      padding: 2px;
    }
    .country_code__single-value .country_name{
      display: none;
    }
  }
}

@media (min-width: 361px) and (max-width: 767px) {
  .country_code_container {
    .country_code__control {
      span.flag {
        margin-right: 8px;
      }
    }

    .country_code__menu {
      width: auto !important;

      .country_code__option {
        span.flag {
          margin-right: 8px;
        }
      }
    }

    .country_code__indicator {
      padding: 4px;
    }
  }
}
