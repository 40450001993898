@import "../../styles/vars";
@import "../../styles/mixins/type";

$table-padding-vertical: 0.75rem;
$table-padding-horizontal: 0.75rem;
$table-footer-color: $color-black;
$table-footer-bg: $color-black98;

$table-header-color: $color-black;
$table-header-bg: transparent;

$table-row-hover-bg: $color-black98;
$table-selected-row-bg: $color-black95;
$table-selected-row-hover-bg: $color-black95;
$table-expanded-row-bg: transparent;

.table {
  position: relative;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  thead > tr > th,
  tbody > tr > td,
  tfoot > tr > th,
  tfoot > tr > td {
    font-size: 13px;
    position: relative;
    padding: $table-padding-vertical $table-padding-horizontal;
    overflow-wrap: break-word;
  }

  &-cell-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;

    // Fixed first or last should special process
    &.table-cell-fix-left-last,
    &.table-cell-fix-right-first {
      overflow: visible;

      .table-cell-content {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &-title {
    padding: $table-padding-vertical $table-padding-horizontal;
  }
  &-footer {
    padding: $table-padding-vertical $table-padding-horizontal;
    color: $table-footer-color;
    background: $table-footer-bg;
  }

  //
  // Header
  // ============================
  thead {
    > tr {
      > th {
        font-size: 11px;
        color: $color-black40;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1em;
        // color: $table-header-color;
        // font-weight: bold;
        text-align: left;
        padding: $table-padding-horizontal;
        background: $table-header-bg;
        border-bottom: 1px solid $color-black90;
        transition: background 0.3s ease;

        &[colspan]:not([colspan='1']) {
          text-align: center;

          .rtl & {
            text-align: center;
          }
        }

        .rtl & {
          text-align: right;
        }
      }
    }

    > tr:not(:last-child) > th {
      &[colspan] {
        border-bottom: 0;
      }
    }
  }

  //
  // Body
  // ============================
  tbody {
    > tr {
      > td {
        border-bottom: 1px solid $color-black90;
        transition: background 0.3s;
        vertical-align: middle;
      }

      &.table-row:hover {
        > td {
          background: $table-row-hover-bg;
        }
      }

      &.table-row-selected {
        > td {
          background: $table-selected-row-bg;
        }

        :hover {
          > td {
            background: $table-selected-row-hover-bg;
          }
        }
      }

      // Nest Table
      .table {
        $font-size-sm: 0.875rem;
        margin: -$table-padding-vertical -$table-padding-horizontal -$table-padding-vertical ($table-padding-horizontal + ceil($font-size-sm * 1.4));

        &.table-rtl {
          margin: -$table-padding-vertical ($table-padding-horizontal + ceil($font-size-sm * 1.4)) -$table-padding-vertical -$table-padding-horizontal;
        }
        td {
          background: transparent;
        }

        tbody > tr:last-child > td {
          border-bottom: 0;

          &:first-child,
          &:last-child {
            border-radius: 0;
          }
        }
      }
    }
  }

  //
  // Summary
  // ============================
  tfoot {
    > tr {
      > th,
      > td {
        border-bottom: 1px solid $color-black90;
      }
    }
  }

  //
  // Expandable
  // ============================
  &-expand-icon-col {
    width: 48px;
  }

  &-row-expand-icon-cell {
    text-align: center;
  }

  &-row-expand-icon {
    // .operation-unit();
    position: relative;
    display: inline-flex;
    box-sizing: border-box;

    width: 1rem;
    height: 1rem;
    color: inherit;
    line-height: 1rem;
    vertical-align: middle;
    // background: @component-background;
    // border: @border-width-base @border-style-base @border-color-split;
    // border-radius: @border-radius-base;
    outline: none;
    transition: all 0.3s;
    user-select: none;

    &:focus,
    &:hover,
    &:active {
      border-color: currentColor;
    }

    &::before,
    &::after {
      position: absolute;
      background: currentColor;
      transition: transform 0.3s ease-out;
      content: '';
    }

    &::before {
      top: 7px;
      right: 3px;
      left: 3px;
      height: 1px;
    }

    &::after {
      top: 3px;
      bottom: 3px;
      left: 7px;
      width: 1px;
      transform: rotate(90deg);
    }

    // Motion effect
    &-collapsed::before {
      transform: rotate(-180deg);
    }
    &-collapsed::after {
      transform: rotate(0deg);
    }

    &-spaced {
      &::before,
      &::after {
        display: none;
        content: none;
      }
      background: transparent;
      border: 0;
    }

    .table-row-indent + & {
      margin-right: 1rem;

      .table & {
        margin-right: 0;
        margin-left: 1rem;
      }
    }
  }

  tr#{&}-expanded-row {
    &,
    &:hover {
      > td {
        background: $table-expanded-row-bg;
      }
    }
  }

  // With fixed
  .table-expanded-row-fixed {
    position: relative;
    margin: -$table-padding-vertical -$table-padding-horizontal;
    padding: $table-padding-vertical $table-padding-horizontal;
  }
}
